.step-border {
  border: 1px solid rgba(255, 255, 255, 0.4) !important;
}

.step-border-active {
  border: 1px solid var(--custom-white) !important;
}

.step-border-v {
  border-left: 1px solid var(--custom-border-grey) !important;
  height: 5rem;
  margin-top: -15px;
  margin-bottom: -15px;
}

.step-border-v-active {
  border-left: 1px solid var(--custom-primary) !important;
  height: 5rem;
  margin-top: -15px;
  margin-bottom: -15px;
}

.ion-ios-play:before {
  content: "";
  color: #F3F4F6;
}
