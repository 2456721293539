.checkbox {
  display: inline-flex;
  cursor: pointer;
  position: relative;
}

/* // .checkbox > span {
//   color: #34495e;
//   padding: 0.5rem 0.25rem;
// } */

.checkbox > input {
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: 1px solid #c8c6c4;
  border-radius: 4px;
  outline: none;
  transition-duration: 0.3s;
  background-color: #fff;
  cursor: pointer;
}

.checkbox > input:checked {
  border: 1px solid var(--custom-primary);
  background-color: var(--custom-primary);
}

.checkbox > input:checked + span::before {
  content: "\2713";
  display: block;
  text-align: center;
  color: #fff;
  position: absolute;
  left: 0.2rem;
  top: -2px;
}

.checkbox > input:active {
  border: 2px solid #34495e;
}

.error-text {
  color: red;
  font-size: 10px;
}
