.dashboard-aside {
  max-width: 17vw;
  min-width: 17vw;
  height: 100vh;
  background-color: #286a3a;
}

.tab-item {
  cursor: pointer;
}

.top-bar-text {
  font-size: 10px;
}

/* top bar  */
.top-bar {
  border-bottom: 1px solid #e5e7eb;
  min-width: 83vw;
}

.navigation-menu-text {
  font-size: 11px;
  color: white;
}

.sliding-aside {
  background-color: #286a3a;
}